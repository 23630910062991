import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Layout from '../components/layout'
import Moment from 'react-moment';

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulNfnPost')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet>
          {/* <!-- Primary Meta Tags --> */}
            <title>{`${post.postTitle} | ${siteTitle}`}</title>
            <meta name="title" content={`${post.postTitle} | ${siteTitle}`} />
            <meta name="description" content="Just the news, no frills." />

            {/* <!-- Open Graph / Facebook --> */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content={`"https://nofrillsnews.com/" + ${post.slug} `} />
            <meta property="og:title" content={`${post.postTitle} | ${siteTitle}`} />
            <meta property="og:description" content="Just the news, no frills." />
            <meta property="og:image" content="" />

            {/* <!-- Twitter --> */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={`"https://nofrillsnews.com/" + ${post.slug} `} />
            <meta property="twitter:title" content={`${post.postTitle} | ${siteTitle}`} />
            <meta property="twitter:description" content="Just the news, no frills." />
            <meta property="twitter:image" content="" />
          </Helmet>
          <div className="wrapper mt-4">
            <h1 className="section-headline">{post.postTitle}</h1>
            <div className="post-inner">
              <p
                style={{
                  display: 'block',
                  textAlign: 'center'
                }}
              >
                {post.publishDate === 'None' ? <Moment parse="YYYY-MM-DD HH:mm">{post.createdLocal}</Moment> : <Moment>{post.publishDate}</Moment> }
                <br></br>
                <strong>{post.feedName}</strong>
                <br></br>
              </p>
              <a href={post.sourceUrl.sourceUrl} className="sourceBtn" target="_blank">ARTICLE SOURCE</a>

              <div
                style={{
                  textAlign: 'left'
                }}
                className="mt-4 mainContent"
                dangerouslySetInnerHTML={{
                  __html: post.postSummary.childMarkdownRemark.html,
                }}
              />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulNfnPost(slug: { eq: $slug }) {
      postTitle
      slug
      createdLocal
      publishDate
      feedName
      sourceUrl {
        sourceUrl
      }
      postSummary {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
